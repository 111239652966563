import axios from 'axios';
import React from "react";
import env from "../config/env";
import globalStyles from "../styles/global.module.css";

class OpenPaywallN extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      paid: false,
      paying: false,
      mounted: false,
      paymentMade: '',
      action: props.action,
      accessToken: props.accessToken,
      email: '',
      value: '',
      submitVIPMemberUrl: env.submitVIPMemberUrl,
      VIPMembersUrl: env.VIPMembersUrl,
      size: "",
      chargingIsBlocked: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleRegisterFormSubmit = this.handleRegisterFormSubmit.bind(this);
    this.loadStripe();
  }

  componentDidMount() {
    this.purchase();
  }

  stripe;
  card;

  loadStripe() {
    if (typeof window !== 'undefined' && typeof window.Stripe !== 'undefined') {
      try {
        this.stripe = window.Stripe(this.props.accessToken);
        console.log('Loaded Stripe Access Token');
      } catch (e) {
        console.error('STRIPE', e);
      }
    } else {
      console.log("Stripe Isn't Loaded");
    }
  }

  createElements() {
    if (!this.stripe) {
      console.warn("Stripe Isn't Loaded");
      return;
    }

    try {
      const elements = this.stripe.elements();
      this.card = elements.create('card', {style: this.stripeStyle});
      this.card.mount('#card-element');
    } catch (e) {
      console.error('STRIPE', e);
    }
  }

  purchase() {
    console.log('Creating purchase');

    this.setState({
      paying: true
    }, () => this.createElements());
  }

  async charge() {
    console.log('charge');
    const {token, err} = await this.stripe.createToken(this.card);

    if (err) {
      const errorElement = document.getElementById('card-errors');
      errorElement.textContent = err.message;
    } else {
      if (!token) {
        this.props.showErrorMessage("Check your card data and try again!");
      } else {
        await this.handleToken(token);
      }
    }
  }

  async handleToken(token) {
    // Post To Action URL
    if (!this.state.action) {
      alert('No Server For Payments');
    }

    const stripeCreateChargeUrl = env.stripeCreateChargeUrl;
    try {
      const createChargeResponse = await axios({
        method: 'post',
        url: stripeCreateChargeUrl,
        data: {
          "customer": this.state.email,
          "description": `MEMBERSHIP: ${this.state.email}`,
          "token": token,
          "charge": {
            "amount": this.props.cost*100,
            "currency": "usd"
          }
        },
        headers: {
          'Access-Control-Allow-Origin': window.location.origin,
        },
      });

      if(createChargeResponse) {
        this.props.onPaymentCompleted(this.state.size, this.state.email, createChargeResponse);
      }
    } catch (error) {
      if(error.response.data.error === "An error occurred with our connection to Stripe.") {
        await this.handleToken(token);
      } else {
        this.props.showErrorMessage(error.response.data.error);
      }
    }
  };

  async handleRegisterFormSubmit (e) {
    e.preventDefault();
    try {
      if (!this.state.size) {
        this.props.showErrorMessage("MOM Tank size field is required.");
        return;
      }

      if (!this.state.email) {
        this.props.showErrorMessage("Email field is required.");
        return;
      }

      if (this.state.chargingIsBlocked) {
        return;
      }

      this.state.chargingIsBlocked = true;

      // check if an email and last4 combo exists in the VIPMember list
      const response = await axios({
        method: 'get',
        url: this.props.VIPMembersUrl + this.state.email,
        headers: {
          'Access-Control-Allow-Origin': window.location.origin,
        },
      });

      //if email address does not exist in VIP Members list then hide email form and show payment form
      if( response.data.memberExists === false ) {
        await this.charge();
      } else {
        this.props.showErrorMessage("You are already a member. Sign in!");
      }

      this.state.chargingIsBlocked = false;
    } catch (error) {
      console.log(error)
      this.state.chargingIsBlocked = false;
    }
  };

  handleFormChange (e) {
    this.setState({email: e.target.value});
  };

  handleSizeChange (e) {
    this.setState({size: e.target.value});
  };

  render() {
    // const wall = (
    //   <div className={"open-paywall " + this.props.className}>
    //     {this.props.children}
    //     <div className="wall" style={this.wallStyle}>
    //       <button className="purchase" onClick={(e) => this.purchase(e)}>{this.props.wallButtonValue}</button>
    //     </div>
    //   </div>
    // );

    const paywall = (
      <div className={"open-paywall " + this.props.className}>
        {this.props.children}
        <div className="paywall payments">
          <form onSubmit={(e) => this.handleRegisterFormSubmit(e)} name="register">
            <select id="size" onChange={this.handleSizeChange}
                    value={this.state.size}
                    placeholder="Select your MOM Tank Size">
              <option className="defaultValue" value="" disabled>Select your MOM Tank size</option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
            </select>
            <input className={globalStyles.openPaywallEmailForm + " inputPlaceholder"} value={this.state.email}
                   type="email" name="email" placeholder="Enter your email address"
                   onChange={this.handleFormChange}/>
            <div className="card-form">
              <div id="card-element">
              </div>
              <div id="card-errors" role="alert"></div>
            </div>
            <button>{this.props.buyButtonValue} ${this.props.cost}</button>
          </form>
        </div>
      </div>
    );

    if (!this.state.paid) {
      // if (this.state.paying) {
        return paywall;
      // } else {
      //   return wall;
      // }
    } else {
      return (
        <div className="premium-content">{this.props.children}</div>
      );
    }
  }

  wallStyle = {
    filter: 'none',
    display: 'block',
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '10',
  };

  stripeStyle = {
    base: {
      color: '#e6ebf1',
      lineHeight: '18px',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#e6ebf1'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

}

export default OpenPaywallN;
