import React from "react"
import Layout from "../components/layout";
import {Row, Col} from "react-bootstrap";
import OpenPaywallN from "../components/open-paywalln";
import "./openpaywall.css";
import env from "../config/env";

const openPaywallNConfig = {
  action: env.openPaywallNAction,
  accessToken: env.GATSBY_STRIPE_PUBLIC_KEY,
  cost: env.vipMemberCost
};

const ProtectedContentPage = ({data}) => {

  return (
    <Layout className="site-content">
      <div>
        <div>
          <h1 className="entry-title">Premium Content</h1>

          <Row style={{position: "relative"}}>
            <Col xs={12}>
              <OpenPaywallN
                action= {openPaywallNConfig.action}
                provider="stripe"
                // accessToken="pk_test_UNQAG3sGSPcBh45ltLsVbUnT006KxfRXNp"
                accessToken={openPaywallNConfig.accessToken}
                cost={openPaywallNConfig.cost}
                wallButtonValue="ACCESS PREMIUM CONTENT"
                buyButtonValue="BUY FOR"
              >
                <div className="premium-content"><p>Lorem ipsum dolor amet plaid meh cornhole squid. Tousled small batch
                  dreamcatcher PBR&amp;B, truffaut mixtape tilde unicorn forage snackwave plaid quinoa four dollar toast
                  cliche. Meditation sriracha pabst crucifix normcore readymade listicle. Paleo listicle locavore actually
                  sriracha direct trade kitsch offal, vinyl chicharrones cred.
                </p><p>
                  Tumeric ugh poke yuccie, waistcoat kogi activated charcoal letterpress. Letterpress shoreditch four loko
                  yr bespoke typewriter. Green juice fashion axe man bun cloud bread four loko, pok pok food truck ramps
                  gluten-free cardigan lomo. Sustainable food truck umami you probably haven't heard of them tattooed viral
                  deep v waistcoat polaroid vaporware listicle authentic pop-up.
                </p><p>
                  Crucifix bespoke cardigan small batch heirloom deep v occupy migas irony pop-up you probably haven't heard
                  of them enamel pin chartreuse retro marfa. Marfa vice godard shaman, squid umami paleo unicorn quinoa
                  glossier. Mumblecore bespoke gluten-free fam, adaptogen shoreditch farm-to-table authentic sriracha shabby
                  chic lumbersexual humblebrag distillery kale chips glossier. Crucifix tilde tofu kinfolk disrupt
                  sustainable. Wolf woke glossier tote bag tattooed, twee franzen deep v. Before they sold out ugh umami
                  meditation wolf.
                </p><p>
                  Kitsch letterpress photo booth flannel poke, prism franzen messenger bag umami. Craft beer tofu iPhone
                  offal paleo four loko heirloom typewriter brunch. Pabst PBR&amp;B green juice deep v ennui swag 8-bit
                  venmo ethical umami raw denim. Meh bitters cold-pressed keytar chicharrones, VHS hexagon. Biodiesel echo
                  park health goth chicharrones street art, bespoke iPhone trust fund vegan. Bitters ramps kogi, schlitz
                  butcher cray squid air plant gastropub austin whatever normcore neutra artisan prism. Humblebrag iceland
                  jianbing, crucifix gastropub air plant venmo edison bulb vape tattooed messenger bag +1.
                </p><p>
                  Oh. You need a little dummy text for your mockup? How quaint.
                </p><p>
                  I bet you’re still using Bootstrap too…</p></div>
              </OpenPaywallN>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
};

export default ProtectedContentPage
